<template>
	<div class="mshipsFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 md6 lg3>
				<pui-field :label="$t('header.mships.imo')" :value="getImoValue"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="$t('header.mships.shipname')" :value="getShipnameValue"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="$t('header.mships.flagdesc')" :value="getFlagdescValue"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="$t('header.mships.statusdesc')" :value="getStatusValue"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mships-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mships'
		};
	},
	computed: {
		getImoValue() {
			return this.model && this.model.imo ? this.model.imo : '-';
		},
		getShipnameValue() {
			return this.model && this.model.shipname ? this.model.shipname : '-';
		},
		getFlagdescValue() {
			return this.model && this.model.flagdesc ? this.model.flagdesc : '-';
		},
		getStatusValue() {
			return this.model && this.model.statusdesc ? this.model.statusdesc : '-';
		}
	}
};
</script>
